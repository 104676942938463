html, body { 
  height: 100%; 
  background-color: #E8ECEE;
} 

#root{
  height:inherit;
}

.App {
    max-width: 960px;
    margin: 10px auto;
  }

  img.nav {
    width: 13em;
    padding: 0.5em;
    border-right: 0.01em solid white;
    padding-right: 1em;
    margin-right: 1em;
  }

  .dashboard-container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .login-logo{
    width: 25em;
    align-self: center;
  }

  .login-logo-container{
    text-align: center;
    margin: 3em;
  }

  .navbar{
    background-color: #222222!important;
  }

  .toolbar-btns .dropdown-toggle{
    width: 15em;
  }

  .dragzone-div{
    padding: 2em 0em;
    text-align: center;
  }

  .card-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .btn-primary, .btn-primary:hover, .btn-primary:focus{
    background-color: #222222;
    border: none;
  }

  .card-footer{
    margin-top: auto;
  }

  .card-img-top{
    padding: 1em;
    max-height: 6em;
    object-fit: contain;
  }

  .card-deck > .card{
    height: 11em;
    max-width: 15em;
    transition: 0.3s;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
  }

  .toolbar input {
    /* width: 30%; */
    height: 2.5em;
    border: none;
    box-shadow: 1px 1px 7px #D5D5D6;
    padding: 0.5em 1em;
  }

  .toolbar-btns{
    width: 25%;
    text-align: right;
    display: flex;
    justify-content: space-between;
  }

  .drop-btn{
    display: inline-flex;
    justify-content: space-between;
    max-width: 12em;
  }

  .navbar-nav{
    color: white;
  }
  
  .btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle, .show>.btn-secondary.dropdown-toggle:focus{
    background-color: #222222;
    border: none;
    box-shadow: none;
  }

  .dropleft .dropdown-menu{
    min-width: 20em;
  }

  #login-form{
    padding: 3em;
  }

  #login-form input {
    padding-left: 1em;
  }

  #personalLinkMenu{
    min-width: 35em;
    right: 0px;
    left: -11.25em!important;
    top: -2.5em!important;
    padding: 1em;
  }

  #newAppMenu{
    top: -2.5em!important;
    left: -0.25em!important;
  }

  #personal-link-form input {
    width: 100%;
  }

  .app-card{
    cursor: pointer;
  }

  .current {
    /* background-color: #E8ECEE; */
    /* display:none; */
    /* visibility: hidden; */
    opacity: 0.5;
    /* animation: 1s fadeIn;
  animation-fill-mode: forwards; */
  }

  ul {
    list-style: none;
  }

  .fulfilled{
    color: green;
  }

  .unfulfilled{
    color: red;
  }

  /* before below */
  
  .text-center {
    height: 100%;
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  
  /* Dropzone */
  .dropzone {
    padding: 30px;
    height: 10vh;
    border: 5px dashed #7649bc;
    outline: none;
    cursor: pointer;
  }
  .dropzone-active {
    background-color: #f1e2ff;
    border: 5px solid #2fd7b5;
  }
  .dropzone-content {
    align-self: center;
    font-size: 24px;
  }

  .bWXMqQ{
    height:15em!important;
  }
  
  /* Images */
  .file-list {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  .file-item {
    width: calc(33% - 20px);
    height: 300px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin: 10px;
    cursor: move;
    transition: all 0.2s linear;
  }
  .file-img {
    width: 100%;
    height: 100%;
  }

  .dropdown{
    display: inline;
  }

  #dropdown-basic-button{
    background-color: #222222;
    color: white;
    border: none;
    width: 40%;
    height: 100%;
    margin-left: 0.8em;
    text-align: left;
    padding: 1em;
    border-radius: 0.2em;
    justify-content: space-between;
  }

  .toolbar-btns .dropdown-toggle:after { content: none!important; }

  #new-app-menu{
    width: 95%;
    font-size: 1em;
  }

  #personal-link-menu{
    width: 95%;
    height: 25em;
    font-size: 1em;
  }

  .new-app-active{
    background-color: white!important;
    color:black!important;
  }

  .personal-link-active{
    background-color: white!important;
    color:black!important;
  }
  
  #edit-btn {
    border: 1px solid black;
    padding: 0.35em;
    border-radius: 0.2em;
    cursor: pointer;
  }

  .edit-active{
    background-color: #A81B21;
    color: white;
  }

  .close-btn{
    position: absolute;
    right: 0.75em;
    color: #999999;
    z-index: 999;
  }

  .hidden{
    display:none;
  }

  /* Tablets */
  @media (max-width: 1000px) {
    .App {
      max-width: 600px;
    }
    .file-item {
      width: calc(50% - 22px);
      height: 200px;
    }
  }
  
  /* Mobiles */
  @media (max-width: 640px) {
    .App {
      max-width: 100%;
      padding: 0 15px;
    }
    .file-item {
      width: calc(100% - 22px);
      height: 200px;
    }

    .main {
      width: fit-content;
    }

    .wrapper{
      grid-template-columns: repeat(2, 1fr);
    }

    .toolbar-btns{
      width: 55%;
    }

    /* #root{
      width: fit-content;
    } */
  }

  @media (min-width: 641px) and (max-width: 1500px){
    .toolbar-btns{
      width: 35%;
    }
  }

  @media (min-width: 641px){
    .toolbar input {
      width: 30%;
    }
  }